.wrapper{
    & input{
        text-align: center;
    }
    & .input__item{
        width: 50px;
        display: block;
        height: 30px;
        padding: 0;
        border-radius: 0;
      
    }
}