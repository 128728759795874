.wrapper {
	cursor: pointer;
	padding: "5px";

	& .active {
		border-radius: 0;
		// border: 1px solid #311d4f;
	}
}
.box {
	border-radius: 0;

	border: 1px solid transparent;
	&:hover {
		border-radius: 0;

		border: 1px solid #311d4f;
	}
}
.sold__out {
	pointer-events: none;
}
