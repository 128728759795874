.button__pay {
	background: rgb(102, 98, 98);
	&:hover {
		background: #000;
	}
	&.active {
		background: #000;
	}
}
.otp__input {
	outline: none;
	height: 40px;
	width: 40px;
}
.button__action {
	padding: 10px;
	width: 50%;
}
.booking_modal_timePicker {
	background: rgba(0, 0, 0, 0.06) !important;
	border-bottom: 1px solid black !important;
	position: relative;
	&::after {
		position: absolute;
		content: "";
		width: 0%;
		left: 0;
		bottom: 0;
		right: 0;
		border: 0.8px solid #2196f3;
		transition: transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
	}
	&:hover {
		background: rgba(0, 0, 0, 0.1) !important;
		border-color: transparent !important;
		border-bottom: 1px solid black !important;
	}
	&:focus {
		border-color: transparent !important;
	}
}

.disabled__area {
	opacity: 0.5;
	pointer-events: none;
}

//Custom InputNumber
.custom__inputNum {
	&.center input {
		text-align: center;
	}
	&.right input {
		text-align: right;
	}
}

.input__number input {
	text-align: right !important;
}
