.button__pay {
	background: rgb(102, 98, 98);
	&:hover {
		background: #000;
	}
	&.active {
		background: #000;
	}
}
.otp__input {
	outline: none;
	height: 40px;
	width: 40px;
}
.button__action {
	padding: 10px;
	width: 50%;
	background: #000;
	&:hover {
		background: rgb(102, 98, 98);
	}
}

//Employee Item
.wrapper {
	display: flex;
	justify-content: flex-start;
	gap: 20px;
	padding: 10px;
	position: relative;
	align-items: center;

	&:hover,
	&.active {
		padding: 10px;

		background-color: #f2f2f2;
		cursor: pointer;
	}

	&:hover .icon-check,
	&.active .icon-check {
		right: 24px;
	}
}
.image {
	justify-content: center;
	align-items: center;
	border-radius: 50%;
	height: 40px;
	width: 40px;
	overflow: hidden;
}
.image img {
	object-fit: cover;
	object-position: center;
	height: auto;
	width: 100%;
}

.icon-check {
	position: absolute;
	right: 0;
	top: 50%;
	transform: translate(0, -50%);
	color: white;
	display: flex;
	border-radius: 50%;
	background-color: #000;
}
