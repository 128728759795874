.style__icon {
	&.square {
		border-radius: 8px;
	}
	&.circle {
		border-radius: 50%;
	}

	&.large {
		height: 62px;
		width: 62px;
	}
	&.small {
		height: 40px;
		width: 40px;
	}
	&.medium {
		height: 48px;
		width: 48px;
	}
}

.link {
	cursor: pointer;
}
