.aspect__ratio {
	aspect-ratio: 5/3;
	display: flex;
	align-items: center;
	padding: 0px;
}
.qr__code {
	height: 100%;
	width: 100%;
}
.qr__img {
	width: 100%;
	object-fit: contain;
	object-position: center;
}
.product__name {
	display: -webkit-box;
	-webkit-line-clamp: 3;
	-webkit-box-orient: vertical;
	overflow: hidden;
}
