.button-85 {
	z-index: 1000000;
	border: none;
	outline: none;
	color: rgb(255, 255, 255);
	background: var(--main-color);
	cursor: pointer;
	user-select: none;
	-webkit-user-select: none;
	touch-action: manipulation;
	position: fixed;
	bottom: 50px;
	right: 70px;
	font-size: 18px;
	height: 65px;
	width: 65px;
	border-radius: 50%;
	display: flex;
	justify-content: center;
	align-items: center;
	transition: 0.5s;
	animation: zoominoutsinglefeatured 1.5s infinite;
	img {
		width: 85%;
		z-index: 100000000000;
	}
	svg {
		z-index: 100000000000;
	}
}

.button-85:before {
	z-index: 1000000;

	content: "";
	background: linear-gradient(45deg, #311d4f, #311d4f);
	position: absolute;
	top: -2px;
	left: -2px;
	background-size: 400%;
	filter: blur(5px);
	-webkit-filter: blur(10px);
	width: calc(100% + 4px);
	height: calc(100% + 4px);
	animation: glowing-button-85 20s linear infinite;
	transition: opacity 0.3s ease-in-out;
	border-radius: 50%;
}

@keyframes glowing-button-85 {
	0% {
		background-position: 0 0;
		transform: scale(1, 1);
	}
	50% {
		background-position: 400% 0;
		transform: scale(1.2, 1.2);
	}
	100% {
		background-position: 0 0;
		transform: scale(1, 1);
	}
}
@keyframes zoominoutsinglefeatured {
	0% {
		transform: scale(1, 1);
	}
	50% {
		transform: scale(1.2, 1.2);
	}
	100% {
		transform: scale(1, 1);
	}
}

.button-85:after {
	z-index: -1;

	content: "";
	position: absolute;
	width: 100%;
	height: 100%;
	background: var(--main-color);
	left: 0;
	top: 0;
	border-radius: 50%;
	transition: opacity 0.3s ease-in-out;
	img {
		width: 85%;
	}
	// animation: zoominoutsinglefeatured 1.5s infinite;
}
