.wrapper{
    cursor: pointer;
    
    &.active{
        background-color: #f6f6f6;
    }
}
.box{
    border-radius: 0;
    &:hover{
        background-color: #f6f6f6;
    }
}
.sold__out{
    background-color: #f2f2f2;
    pointer-events: none;
}