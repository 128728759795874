.search__box {
	width: 100%;
}

.search__item {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 20px;
	cursor: pointer;
	&:hover {
		background: #f6f6f6;
	}
	&.active {
		background: #f6f6f6;
	}
}

.item__text {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	&.text__line-through {
		text-decoration-line: line-through;
	}
}
