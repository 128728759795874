.wrapper{
    & .input__number input {
        text-align: right!important
    }
}


.form__label{
    font-weight: 700;
    font-size: 16px;
    margin: 10px 5px;
    display: block
}

.form__error{
    font-weight: 500;
    margin: 10px 5px;
    color: red;
}

