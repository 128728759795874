.button__pay{
        background: rgb(102, 98, 98);
    &:hover{
        background: #000;
    }
    &.active{
        background: #000;
    }
}
.otp__input{
    outline: none;
    height: 40px;
    width: 40px;
}
.button__action {
   padding:10px;
    width: 50%;
        background: #000;
    &:hover {
         background: rgb(102, 98, 98);
    }

  
}