.ant-carousel .slick-prev,
.ant-carousel .slick-next {
	color: unset;
	font-size: 20px;
}

.ant-carousel .slick-prev:hover,
.ant-carousel .slick-next:hover,
.ant-carousel .slick-prev:focus,
.ant-carousel .slick-next:focus {
	color: unset;
}

.ant-carousel .slick-prev {
	margin-left: 4px;
}

.ant-carousel .slick-next {
	margin-right: 4px;
}

.ant-carousel .slick-dots li.slick-active button {
	background-color: #000;
}

.ant-carousel .slick-dots li button {
	background-color: #000;
}
