.content__item {
	display: flex;

	align-items: center;
	gap: 10px;
}

.button__confirm {
	color: #311d4f;
	border-color: #311d4f;
	&:hover {
		border-color: #311d4f;
	}
}

.tag__color {
	background-color: #311d4f;
	color: #fff;
	&:hover {
		border-color: #311d4f;
		background-color: #311d4f9e !important;
	}
}
.tag {
	width: 100px;
}

.button__submit {
	color: #fff;
	border-color: #311d4f;
	background-color: #311d4f;
	&:hover {
		border-color: #311d4f;
		background-color: #311d4f9e;
	}
}
