.wrapper {
	display: inline-flex;
	justify-content: space-between;
	align-items: center;
    gap:20px;  
    font-size: 12px; 
    width: 100%;
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 5px;
}
.image{
    height: 80px;
    width: 80px;
    overflow: hidden;
    border-radius: 5px;
    display: flex;
    align-items: center;
    & img{
        width: 100%;
        height: auto;
        object-fit: contain;
        object-position: center;
        border-radius: 5px;
    }
}
.content {
    flex: 1;
	& p {
		font-weight: 400;
	}
	& span {
		font-weight: 700;
	}
}
.content_row{
    display: flex;
    justify-content: space-between;
}

.btn__assign{
    min-height: 130px;
    background-color: #000;
    color: white;
    border-radius: 0;
    width: 5%;
    &:hover{
        background-color: #000;
        opacity: 0.75;
    }
}