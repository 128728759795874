//Loading

.wrapper__loading {
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}
//No Data
.no_service {
	height: 100%;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	span {
		margin-top: 30px;
		font-size: 16px;
		line-height: 24px;
		font-weight: 400;
		color: rgba(137, 137, 137, 1);
	}
}
