.search-container {
	display: flex;
	& .search-box-input {
		height: 51px;
		& .search-input-antd {
			height: 100%;
			border-radius: 10px;
		}
	}
}
